import React, { Fragment } from 'react'
import { StartContainer } from '../styles/startStyles'
import { CircleIllu } from '../styles/globalStyles'
import Layout from '../components/layout'
import LineChart from '../components/LineChart/LineChart'
import { Link } from 'gatsby'

const FeaturePage = () => (
  <Fragment>
    <StartContainer>
      <div className='sell-copy'>
        <h1>Get a good nights sleep.</h1>
        <p>Feature list</p>
      </div>
    </StartContainer>
    <CircleIllu />
  </Fragment>
)

export default () => (
  <Layout>
    <FeaturePage />
  </Layout>
)
